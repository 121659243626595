/* @flow */

import React, { Component } from "react";

import { SettingsBox, SettingsTitle } from "../../StyledComponents/Settings";
import { Row, ColL, Col } from "../../StyledComponents/Grid";
import TextInput from "../Inputs/TextInput";
import CheckBox from "../Inputs/CheckBox";
import ImgInput from "../Inputs/ImgInput";
import Help from "../Help";

export default class Sorting extends Component<any, any> {
  render() {
    const { settings, lang, onChange } = this.props;

    return (
      <div>
        <SettingsTitle>{lang.settings.sorting}</SettingsTitle>
        <SettingsBox>
          {/* <Row>
            <ColL size={2}>
              {lang.settings.CH}
              <Help attr="sorting.CH" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["sorting", "CH"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "CH"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.CK}
              <Help attr="sorting.CK" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["sorting", "CK"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "CK"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.CL}
              <Help attr="sorting.CL" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["bins", "CL"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "CL"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.CR}
              <Help attr="sorting.CR" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["sorting", "CR"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "CR"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row> */}
          <Row>
            <ColL size={2}>
              <label htmlFor="showSorting">
                {lang.settings.showSorting}
                <Help attr="sorting.showSorting" />
              </label>
            </ColL>
            <ColL size={2}>
              <CheckBox
                path={["sorting", "showSorting"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "showSorting"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              <label htmlFor="swapSides">
                {lang.settings.swapSides}
                <Help attr="sorting.swapSides" />
              </label>
            </ColL>
            <ColL size={2}>
              <CheckBox
                path={["sorting", "swapSides"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "swapSides"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={1}>
              {lang.settings.leftBin}
              <Help attr="sorting.leftBin" />
            </ColL>
            <ColL size={1}>
              <ImgInput
                style={{ width: 100 }}
                obj={settings}
                path={["sorting", "leftBin"]}
                onChange={onChange}
              />
            </ColL>
            <ColL size={2}>
              <TextInput
                style={{ alignSelf: "flex-start" }}
                type="text"
                placeholder={lang.settings.wording}
                path={["sorting", "leftBinWording"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "leftBin:leftBinWording"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={1}>
              {lang.settings.rightBin}
              <Help attr="sorting.rightBin" />
            </ColL>
            <ColL size={1}>
              <ImgInput
                style={{ width: 100 }}
                obj={settings}
                path={["sorting", "rightBin"]}
                onChange={onChange}
              />
            </ColL>
            <ColL size={2}>
              <TextInput
                style={{ alignSelf: "flex-start" }}
                type="text"
                placeholder={lang.settings.wording}
                path={["sorting", "rightBinWording"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "rightBin:rightBinWording"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
        </SettingsBox>
      </div>
    );
  }
}
