/* @flow */

import { Map, fromJS } from 'immutable'
import { GET_REPORT_SUCCESS } from '../constants/actions'

export default (state: IMap = new Map(), action: Action) => {

  switch (action.type) {

    case GET_REPORT_SUCCESS: {
      const { report } = action.payload
      return fromJS(report)
    }

    default:
      return state
  }
}
