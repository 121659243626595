import { delay } from "redux-saga";
import { takeLatest, all, put, select } from "redux-saga/effects";
import { getUI, getLang, getAccount } from "../selectors";
import { startGetInfo, uiSet } from "../actionCreators";
import { DO_UPDATE_SUCCESS, GET_INFO_SUCCESS } from "../constants/actions";

function* checkUpdates(action) {
  while (true) {
    const account = yield select(getAccount);
    if (account.token) {
      yield put(startGetInfo());
      yield delay(3 * 60 * 1000);
    } else yield delay(1 * 1000);
  }
}

function* waitForUpdate(action) {
  const lang = yield select(getLang);
  yield put(uiSet({ path: ["updating"], value: true }));
  window.alert(lang.waitForUpdate);
}

function* updateDone(action) {
  const ui = yield select(getUI);
  const isUpdating = ui.get("updating", false);
  if (isUpdating) {
    window.location.reload();
  }
}

function* saga() {
  yield all([
    checkUpdates(),
    yield takeLatest(DO_UPDATE_SUCCESS, waitForUpdate),
    yield takeLatest(GET_INFO_SUCCESS, updateDone)
  ]);
}

export default saga;
