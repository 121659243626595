/* @flow */

import { List } from "immutable";

export const getRouting = (state: State) => state.routing;

export const getAccount = (state: State) => state.account;

export const getInfo = (state: State) => state.info;

export const getUI = (state: State) => state.ui;

export const getLibraries = (state: State) => state.libraries;

export const getUsers = (state: State) => state.users;

export const getCounts = (state: State) => state.counts;

export const getLibrary = (libraries: IMap, id: any) => libraries.get(id);

export const getSettings = (state: State) => state.settings;
export const getSettingsJs = (state: State) => state.settings.toJS();

export const getTheme = (state: State) => state.theme;
export const getThemeJS = (state: State) => state.theme.toJS();

export const getLanguages = (state: State) => state.languages;
export const getLanguagesJS = (state: State) => state.languages.toJS();

export const getLang = (state: State) => state.lang;

export const getPayments = (state: State) => state.payments;

export const getReport = (state: State) => state.report;

export const getConstants = (state: State) => state.constants;

export const getMisc = (state: State, key: string) => state.misc.get(key);

export const getConstantsFor = (settings: IMap, language: string) => {
  const list = settings.getIn(["constants", language]);
  return list ? list : new List();
};

export const getSelfCheck = (libraries: IMap, id: any) => {
  return libraries
    .valueSeq()
    .map((e) => e.get("selfChecks"))
    .flatten(true)
    .find((e) => e.get("id") === id);
};

export const getSelfChecks = (libraries: IMap) => {
  return libraries
    .valueSeq()
    .map((e) => e.get("selfChecks"))
    .flatten(true);
};

export const getUser = (users: IList, id: any) => {
  return users.find((e) => e.get("id") === id);
};

export const getCanChange = (state: State) => getAccount(state).userType >= 20;

export const getCanAdmin = (state: State) => getAccount(state).userType >= 30;

export const getRoute = (routing: Object) => {
  const { location } = routing;
  const { pathname } = location;
  const parts = pathname.split("/").filter((e) => e);
  return { path: parts[0], id: parts[1] };
};
