import { takeEvery, put, select } from "redux-saga/effects";
import { REQUEST } from "../constants/actions";
import { getAccount } from "../selectors";
import { fatalError, startLogout } from "../actionCreators";
import axios from "axios";

let base;
if (process.env.NODE_ENV === "production") {
  base = "/";
} else {
  base = "http://localhost:9000/";
}

function* request(action: Action) {
  const {
    route,
    method,
    postData,
    token,
    successCallback,
    errorCallback
  } = action.payload;
  try {
    const account = yield select(getAccount);
    const { data } = yield axios({
      method,
      url: base + route,
      data: postData,
      headers: token ? { Authorization: "Token " + account.token } : null
    });
    if (data.success) yield successCallback(data);
    else yield errorCallback(data.error);
  } catch (e) {
    if (e && e.response && e.response.status === 401)
      yield put(startLogout(false));
    else yield put(fatalError(e));
  }
}

function* saga() {
  yield takeEvery(REQUEST, request);
}

export default saga;
