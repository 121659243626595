/* @flow */

import React, { Component, Fragment } from "react";
import { Btn } from "../../StyledComponents/Forms";
import FA from "react-fontawesome";

export default class ImgInput extends Component<any, any> {
  onChange = (e: Event) => {
    const { path, onChange } = this.props;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = function() {
      const data = "data:image/png;base64," + btoa(reader.result);
      onChange(path)(data);
    };
  };

  reset = (e: any) => {
    const { path, onChange } = this.props;
    onChange(path)(null);
  };

  render() {
    const { obj, path, style = {} } = this.props;
    const data = obj.getIn(path);

    return (
      <Fragment>
        <div style={{ display: "flex", ...style }}>
          {data ? (
            <Btn
              icon={1}
              danger={1}
              tiny={1}
              onClick={this.reset}
              style={{ marginRight: 10 }}
            >
              <FA name="remove" />
            </Btn>
          ) : null}
          <input type="file" onChange={this.onChange} accept="image/*" />
        </div>

        {data ? (
          <img alt="" style={{ marginTop: 10 }} width="180" src={data} />
        ) : null}
      </Fragment>
    );
  }
}
