/* @flow */

import { createStore, applyMiddleware, combineReducers } from "redux";

import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import { routerReducer, routerMiddleware } from "react-router-redux";

import account from "../reducers/account";
import ui from "../reducers/ui";
import libraries from "../reducers/libraries";
import settings from "../reducers/settings";
import theme from "../reducers/theme";
import lang from "../reducers/lang";
import languages from "../reducers/languages";
import payments from "../reducers/payments";
import report from "../reducers/report";
import info from "../reducers/info";
import users from "../reducers/users";
import counts from "../reducers/counts";
import misc from "../reducers/misc";

export default function configureStore(history: any) {
  const rootReducer = combineReducers({
    account,
    ui,
    lang,
    libraries,
    settings,
    theme,
    languages,
    payments,
    report,
    info,
    users,
    counts,
    misc,

    routing: routerReducer
  });

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer,
    {},
    applyMiddleware(sagaMiddleware, routerMiddleware(history))
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
