/* @flow */

import React, { Component } from 'react'

import { Input } from '../../StyledComponents/Forms'
import last from 'lodash/last'


export default class NumberInput extends Component<any,any> {

  onChange = (path: Array<string>) => (e: Event) => {
    const { onChange } = this.props
    onChange(path)(parseInt(e.target.value, 10))
  }

  render() {
    const { obj, path, onChange, ...rest } = this.props
    const id = last(path)

    return (
      <Input type='number' id={id} onChange={this.onChange(path)} value={obj.getIn(path, '')} {...rest} />
    )
  }
}
