/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import { getMisc } from "../../Data/selectors";
import { Tooltip, Icon } from "antd";

class Help extends Component<any, any> {
  render() {
    const { tooltips, attr } = this.props;

    return tooltips ? (
      tooltips.get(attr) ? (
        <Tooltip placement="right" title={tooltips.get(attr, "")}>
          <Icon type="question-circle" />
        </Tooltip>
      ) : null
    ) : null;
  }
}

export default connect((state: State) => ({
  tooltips: getMisc(state, "tooltips")
}))(Help);
