/* @flow */

import { GET_INFO_SUCCESS } from "../constants/actions";

export default (state: Object = {}, action: Action) => {
  switch (action.type) {
    case GET_INFO_SUCCESS: {
      const { version, newVersion, expiring, features } = action.payload;
      const featuresList = features.split(",").reduce((acc, v) => {
        acc[v] = true;
        return acc;
      }, {});
      return { version, newVersion, expiring, features: featuresList };
    }

    default:
      return state;
  }
};
