/* @flow */

import React, { Component } from "react";

export default class Updating extends Component<any, any> {
  render() {
    const { lang } = this.props;

    return <div className="updating-overlay">{lang.updating}</div>;
  }
}
