/* @flow */

import React, { Component } from "react";

import { Link } from "react-router-dom";
import { Sidebar, SidebarItem } from "../StyledComponents/Main";
import FA from "react-fontawesome";

export default class SettingsSidebar extends Component<any, any> {
  render() {
    const { lang, id, tab } = this.props;

    return (
      <Sidebar>
        <Link to="/">
          <SidebarItem main={true}>
            <FA name="caret-left" size="2x" /> <h2>{lang.settings.back}</h2>
          </SidebarItem>
        </Link>
        <Link to={"/settings/" + id + "/lms"}>
          <SidebarItem active={tab === "lms"}>{lang.settings.lms}</SidebarItem>
        </Link>
        <Link to={"/settings/" + id + "/design"}>
          <SidebarItem active={tab === "design"}>
            {lang.settings.design}
          </SidebarItem>
        </Link>
        <Link to={"/settings/" + id + "/chippin"}>
          <SidebarItem active={tab === "chippin"}>
            {lang.settings.chippin}
          </SidebarItem>
        </Link>
        <Link to={"/settings/" + id + "/EMDevice"}>
          <SidebarItem active={tab === "EMDevice"}>
            {lang.settings.EMDevice}
          </SidebarItem>
        </Link>
        <Link to={"/settings/" + id + "/money"}>
          <SidebarItem active={tab === "money"}>
            {lang.settings.money}
          </SidebarItem>
        </Link>
        <Link to={"/settings/" + id + "/timing"}>
          <SidebarItem active={tab === "timing"}>
            {lang.settings.timing}
          </SidebarItem>
        </Link>
        <Link to={"/settings/" + id + "/sorting"}>
          <SidebarItem active={tab === "sorting"}>
            {lang.settings.sorting}
          </SidebarItem>
        </Link>
        {/* <Link to={"/settings/" + id + "/userCard"}>
          <SidebarItem active={tab === "userCard"}>
            {lang.settings.userCard}
          </SidebarItem>
        </Link> */}
        <Link to={"/settings/" + id + "/constants"}>
          <SidebarItem active={tab === "constants"}>
            {lang.settings.constants}
          </SidebarItem>
        </Link>
        <Link to={"/settings/" + id + "/receipts"}>
          <SidebarItem active={tab === "receipts"}>
            {lang.settings.receipts}
          </SidebarItem>
        </Link>
      </Sidebar>
    );
  }
}
