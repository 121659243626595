/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";

import { getLang, getCanAdmin, getLibraries } from "../../Data/selectors";
import { startLogout } from "../../Data/actionCreators";

import { Container, Body, Footer } from "../StyledComponents/Page";
import { Row, Col } from "../StyledComponents/Grid";
import { MainArea } from "../StyledComponents/Main";
import MainSidebar from "../Components/MainSidebar";

export class Help extends Component<any, any> {
  logout = () => {
    const { dispatch } = this.props;
    dispatch(startLogout());
  };

  render() {
    const { libraries, canAdmin, lang } = this.props;

    return (
      <Container>
        <Body>
          <MainSidebar
            lang={lang}
            libraries={libraries}
            id={null}
            canAdmin={canAdmin}
            logout={this.logout}
          ></MainSidebar>
          <MainArea>
            <Row>
              <Col size={8} bold={true} center={true}>
                <h1 style={{ textAlign: "center" }}>{lang.help}</h1>
              </Col>
            </Row>
            <center style={{ margin: "0 40px" }}>
              <p style={{ color: "black" }}>
                If you have a problem with your D-Tech product, please click on
                the link below which will take you to our support website where
                you will be required to register (if you have not done so
                already). Once you have registered you will be able to log a
                ticket for our dedicated support team to action.
              </p>
              <a href="https://d-techinternational.com/technical-support/">
                https://d-techinternational.com/technical-support/
              </a>
              <p style={{ color: "black", marginTop: 30 }}>
                Please visit our online shop for the latest deals on our library
                accessories range!
              </p>
              <a href="https://www.d-techshop.com">
                https://www.d-techshop.com
              </a>
            </center>

            <div
              style={{
                margin: "0 20px",
                marginTop: 40,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  flex: 1,
                  border: "1px solid #000",
                  marginRight: 10,
                  padding: 10,
                }}
              >
                <b>United Kingdom (HQ)</b>
                <br />
                <br />
                D-Tech International Ltd
                <br />
                Building 136
                <br />
                Bentwaters Parks
                <br />
                Rendlesham
                <br />
                Woodbridge
                <br />
                Suffolk
                <br />
                IP12 2TW
                <br />
                <a href="tel:+441394420077">+44 (0)1394 420077</a>
                <br />
                <a href="mailto:info@d-techinternational.com">
                  info@d-techinternational.com
                </a>
              </div>
              <div
                style={{
                  flex: 1,
                  border: "1px solid #000",
                  marginLeft: 10,
                  padding: 10,
                }}
              >
                <b>USA</b>
                <br />
                <br />
                D-Tech International USA LLC
                <br />
                Unit 1
                <br />
                251 Ranger Road
                <br />
                Rio Grande
                <br />
                New Jersey
                <br />
                08242
                <br />
                <a href="tel:+16094355846">(609) 435-5846</a>
                <br />
                Toll Free: 855-38-DTECH
                <br />
                <a href="mailto:usainfo@d-techinternational.com">
                  usainfo@d-techinternational.com
                </a>
              </div>
            </div>
          </MainArea>
        </Body>
        <Footer />
      </Container>
    );
  }
}

export default connect((state: State) => ({
  canAdmin: getCanAdmin(state),
  libraries: getLibraries(state),
  lang: getLang(state),
}))(Help);
