import printJS from "print-js";
import jsPDF from "jspdf";
import moment from "moment";
import { takeLatest } from "redux-saga/effects";
import { TRIGGER_PRINT, TRIGGER_ZPRINT } from "../constants/actions";

function triggerPrintGen(action) {
  try {
    const { data } = action.payload;

    const date = moment().format("DD/MM/YYYY HH:mm:ss Z");

    const doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: [90, 80],
    });
    let inc = 10;
    doc.setFontSize(18);
    doc.text(data.title, 8, inc);
    inc += 8;
    doc.setFontSize(12);
    doc.text(data.libName, 8, inc);
    inc += 6;
    doc.text(data.kioskName, 8, inc);
    inc += 6;
    doc.text(data.intervalTitle, 8, inc);
    inc += 6;
    doc.setFontSize(8);
    doc.text(data.kioskId, 8, inc);
    inc += 5;
    doc.text(date, 8, inc);
    inc += 5;
    doc.setFontSize(7);
    doc.text(data.intervalDur, 8, inc);
    inc += 7;
    doc.setFontSize(12);
    doc.text(data.totalTX, 8, inc);
    inc += 6;
    doc.text(data.cashPaidIn, 8, inc);
    inc += 6;
    doc.text(data.cardPaidIn, 8, inc);
    inc += 6;
    doc.text(data.cashChangeGiven, 8, inc);
    inc += 6;
    doc.text(data.overallCash, 8, inc);
    inc += 6;
    doc.text(data.float, 8, inc);
    inc += 6;
    doc.text(data.notes, 8, inc);
    printJS({ printable: doc.output("bloburl") });
  } catch (e) {
    console.error(e);
  }
}

function triggerZPrintGen(action) {
  try {
    const { data } = action.payload;
    const date = moment().format("DD/MM/YYYY HH:mm:ss Z");

    let height = 40;
    if (data.length > 0) {
      if (data[0].council) height += 38;
      if (data[0].payment) height += 38;
      if (data[0].combined) height += 38;
    }

    const doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: [Math.max(height, 80), 80],
    });
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      let inc = 10;
      // Header
      doc.setFontSize(12);
      doc.text(row.libName, 8, inc);
      inc += 6;
      doc.text(row.kioskName, 8, inc);
      inc += 6;
      doc.setFontSize(8);
      doc.text(row.kioskId, 8, inc);
      inc += 6;
      doc.setFontSize(7);
      const since = moment(row.since).format("DD/MM/YYYY HH:mm:ss Z");
      doc.text(since + " To " + date, 8, inc);
      inc += 8;

      // Council
      if (row.council) {
        doc.setFontSize(12);
        doc.text(row.council.title, 8, inc);
        inc += 6;
        doc.setFontSize(8);
        doc.text(row.council.totalTX, 8, inc);
        inc += 5;
        doc.text(row.council.cashPaidIn, 8, inc);
        inc += 5;
        doc.text(row.council.cardPaidIn, 8, inc);
        inc += 5;
        doc.text(row.council.cashChangeGiven, 8, inc);
        inc += 5;
        doc.text(row.council.overallCash, 8, inc);
        inc += 5;
        doc.text(row.council.float, 8, inc);
        inc += 5;
        doc.text(row.council.notes, 8, inc);
        inc += 8;
      }

      // Payment
      if (row.payment) {
        doc.setFontSize(12);
        doc.text(row.payment.title, 8, inc);
        inc += 6;
        doc.setFontSize(8);
        doc.text(row.payment.totalTX, 8, inc);
        inc += 5;
        doc.text(row.payment.cashPaidIn, 8, inc);
        inc += 5;
        doc.text(row.payment.cardPaidIn, 8, inc);
        inc += 5;
        doc.text(row.payment.cashChangeGiven, 8, inc);
        inc += 5;
        doc.text(row.payment.overallCash, 8, inc);
        inc += 5;
        doc.text(row.payment.float, 8, inc);
        inc += 5;
        doc.text(row.payment.notes, 8, inc);
        inc += 8;
      }

      // Combined
      if (row.combined) {
        doc.setFontSize(12);
        doc.text(row.combined.title, 8, inc);
        inc += 6;
        doc.setFontSize(8);
        doc.text(row.combined.totalTX, 8, inc);
        inc += 5;
        doc.text(row.combined.cashPaidIn, 8, inc);
        inc += 5;
        doc.text(row.combined.cardPaidIn, 8, inc);
        inc += 5;
        doc.text(row.combined.cashChangeGiven, 8, inc);
        inc += 5;
        doc.text(row.combined.overallCash, 8, inc);
        inc += 5;
        doc.text(row.combined.float, 8, inc);
        inc += 5;
        doc.text(row.combined.notes, 8, inc);
      }

      if (i < data.length - 1) doc.addPage();
    }

    printJS({ printable: doc.output("bloburl") });
  } catch (e) {
    console.error(e);
  }
}

function* saga() {
  yield takeLatest(TRIGGER_PRINT, triggerPrintGen);
  yield takeLatest(TRIGGER_ZPRINT, triggerZPrintGen);
}

export default saga;
