/* @flow */

import { Map, fromJS } from "immutable";
import {
  GET_LIBS_SUCCESS,
  CREATE_LIB_SUCCESS,
  EDIT_LIB_SUCCESS,
  CREATE_SC_SUCCESS,
  DELETE_LIB_SUCCESS,
  DELETE_SC_SUCCESS,
  EDIT_SC_SUCCESS,
} from "../constants/actions";
import { index } from "../utils";

export default (state: IMap = new Map(), action: Action) => {
  switch (action.type) {
    case GET_LIBS_SUCCESS: {
      const { libraries } = action.payload;
      return index(libraries);
    }

    case CREATE_LIB_SUCCESS: {
      const { library } = action.payload;
      const { id } = library;
      return state.set(id, fromJS({ ...library, status: 1, selfChecks: [] }));
    }

    case EDIT_LIB_SUCCESS: {
      const { library } = action.payload;
      const { id } = library;
      return state.setIn([id, "name"], library.name);
    }

    case CREATE_SC_SUCCESS: {
      const { selfCheck } = action.payload;
      const { library_id } = selfCheck;
      const selfChecks = state.getIn([library_id, "selfChecks"]);
      const newSelfChecks = selfChecks.push(fromJS(selfCheck));
      return state.setIn([library_id, "selfChecks"], newSelfChecks);
    }

    case EDIT_SC_SUCCESS: {
      const { selfCheck } = action.payload;
      const { library_id, id } = selfCheck;
      const selfChecks = state.getIn([library_id, "selfChecks"]);
      const newSelfChecks = selfChecks.map((e) =>
        e.get("id") === id ? fromJS(selfCheck) : e
      );
      return state.setIn([library_id, "selfChecks"], newSelfChecks);
    }

    case DELETE_LIB_SUCCESS: {
      const { id } = action.payload;
      return state.delete(id);
    }

    case DELETE_SC_SUCCESS: {
      const { id } = action.payload;
      return state.map((lib, k) => {
        const selfChecks = lib.get("selfChecks");
        const newSelfChecks = selfChecks.filter((sc) => sc.get("id") !== id);
        return lib.set("selfChecks", newSelfChecks);
      });
    }

    default:
      return state;
  }
};
