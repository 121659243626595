/* @flow */

import React, { Component } from "react";

import { SettingsBox, SettingsTitle } from "../../StyledComponents/Settings";
import { Row, ColL, Col } from "../../StyledComponents/Grid";
import TextInput from "../Inputs/TextInput";
import NumberInput from "../Inputs/NumberInput";
import CheckBox from "../Inputs/CheckBox";
import SelectInput from "../Inputs/SelectInput";
import Help from "../Help";

export default class LMS extends Component<any, any> {
  render() {
    const { settings, lang, onChange } = this.props;

    return (
      <div>
        <SettingsTitle>{lang.settings.lms}</SettingsTitle>
        <SettingsBox>
          <Row>
            <ColL bold={true} size={2}>
              {lang.settings.connectionType}
              <Help attr="lms.connectionType" />
            </ColL>
            <ColL size={2}>
              <SelectInput
                options={["", "Socket", "Telnet"]}
                path={["lms", "connectionType"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "connectionType"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL bold={true} size={2}>
              {lang.settings.hostname}
              <Help attr="lms.hostname" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["lms", "hostname"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "hostname"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL bold={true} size={2}>
              {lang.settings.port}
              <Help attr="lms.port" />
            </ColL>
            <ColL size={2}>
              <NumberInput
                path={["lms", "port"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "port"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL bold={true} size={2}>
              {lang.settings.lmsUser}
              <Help attr="lms.lmsUser" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["lms", "lmsUser"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "lmsUser"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL bold={true} size={2}>
              {lang.settings.lmsPwd}
              <Help attr="lms.lmsPwd" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="password"
                path={["lms", "lmsPwd"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "lmsPwd"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL bold={true} size={2}>
              {lang.settings.institutionId}
              <Help attr="lms.institutionId" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["lms", "institutionId"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "institutionId"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL bold={true} size={2}>
              {lang.settings.terminalPwd}
              <Help attr="lms.terminalPwd" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="password"
                path={["lms", "terminalPwd"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "terminalPwd"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL bold={true} size={2}>
              {lang.settings.locationCode}
              <Help attr="lms.locationCode" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["lms", "locationCode"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "locationCode"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL bold={true} size={2}>
              {lang.settings.borrowDue}
              <Help attr="lms.borrowDue" />
            </ColL>
            <ColL size={2}>
              <NumberInput
                min={0}
                path={["lms", "borrowDue"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "borrowDue"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL bold={true} size={2}>
              {lang.settings.sendLoginToLMS}
              <Help attr="lms.sendLoginToLMS" />
            </ColL>
            <ColL size={2}>
              <CheckBox
                style={{ margin: "auto" }}
                path={["lms", "sendLoginToLMS"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "sendLoginToLMS"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
        </SettingsBox>
      </div>
    );
  }
}
