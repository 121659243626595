/* @flow */

import { Map, fromJS } from 'immutable'
import { GET_PAYMENTS_SUCCESS } from '../constants/actions'

export default (state: IMap = new Map(), action: Action) => {

  switch (action.type) {

    case GET_PAYMENTS_SUCCESS: {
      const { payments } = action.payload
      return fromJS(payments)
    }

    default:
      return state
  }
}
