/* @flow */

import styled from "styled-components";
import logo from "../res/img/logo.png";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  & a {
    color: inherit;
    text-decoration: none;
  }
`;

export const Body = styled.div`
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: row;
`;

export const Footer = styled.div`
  height: 80px;
  background: #5a91b8;
  border-top: 10px solid #d0cecf;
  position: relative;
  box-sizing: border-box;

  &::after {
    content: "";
    display: block;
    background-image: url(${logo});
    background-size: 100% 100%;
    width: 150px;
    height: 90px;
    position: absolute;
    right: 20px;
    top: -50%;
    z-index: 99999;
  }
`;
