/* @flow */

import styled from "styled-components";

export const Row = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: ${props => (props.tiny ? "5px" : "10px 5px")};
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  border-bottom: ${props => (props.bb ? "1px solid #efefef" : "none")};
`;

export const RowG = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ColG = styled.div`
  box-sizing: border-box;
  flex: ${props => props.size || 1};
  display: flex;
  flex-direction: column;
`;

export const Col = styled.div`
  box-sizing: border-box;
  flex: ${props => props.size};
  text-align: ${props => (props.center ? "center" : "left")};
  font-weight: ${props => (props.bold ? "bold" : "normal")};
  font-size: ${props => (props.bold ? "18px" : "normal")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  flex-wrap: wrap;
`;

export const ColL = styled.div`
  box-sizing: border-box;
  flex: ${props => props.size};
  display: flex;
  font-weight: ${props => (props.bold ? "bold" : "normal")};
  flex-direction: row;
  padding: 5px;
  flex-wrap: wrap;

  i {
    margin-left: 15px;
    svg {
      margin-top: 7px;
    }
  }
`;
