/* @flow */

import React, { Component } from "react";

import FA from "react-fontawesome";
import {
  Segment,
  SegmentTitle,
  SegmentRows,
  SegmentRow,
  SegmentCol,
} from "../StyledComponents/Payments";
import { Btn } from "../StyledComponents/Forms";

export default class PaymentSegment extends Component<any, any> {
  render() {
    const { lang, name, extraName, payments, onExport, onPrint } = this.props;

    return (
      <Segment>
        <SegmentTitle>
          {lang[name]} {extraName ? " - " + extraName : ""}
        </SegmentTitle>
        <SegmentRows>
          <SegmentRow>
            <SegmentCol primary>{lang.cash}</SegmentCol>
            <SegmentCol>{(payments.get("cash") || 0).toFixed(2)}</SegmentCol>
          </SegmentRow>
          <SegmentRow>
            <SegmentCol primary>{lang.cards}</SegmentCol>
            <SegmentCol>{(payments.get("card") || 0).toFixed(2)}</SegmentCol>
          </SegmentRow>
          <SegmentRow>
            <SegmentCol primary>{lang.changeGiven}</SegmentCol>
            <SegmentCol>{(payments.get("change") || 0).toFixed(2)}</SegmentCol>
          </SegmentRow>
          <SegmentRow>
            <SegmentCol primary>{lang.float}</SegmentCol>
            <SegmentCol>{(payments.get("ffloat") || 0).toFixed(2)}</SegmentCol>
          </SegmentRow>
          <SegmentRow>
            <SegmentCol primary>{lang.notes}</SegmentCol>
            <SegmentCol>{(payments.get("notes") || 0).toFixed(2)}</SegmentCol>
          </SegmentRow>
        </SegmentRows>
        <SegmentRow>
          {onExport && (
            <Btn primary={true} style={{ marginRight: 10 }} onClick={onExport}>
              <FA name="save" /> Export CSV
            </Btn>
          )}
          {onPrint && (
            <Btn onClick={onPrint}>
              <FA name="print" /> Print
            </Btn>
          )}
        </SegmentRow>
      </Segment>
    );
  }
}
