/* @flow */

import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'


const options = {
  responsive: true,
  tooltips: { mode: 'label' },
  elements: { line: { fill: false } },
  legend: { display: false },
  scales: {
    xAxes: [ { display: true, gridLines: { display: false } } ],
    yAxes: [ { display: true, gridLines: { display: false }, ticks: { beginAtZero: true } } ]
  }
}

const getData = (lang, counts) => ({
  labels: [lang.issues, lang.returns, lang.renews, lang.failedIssues,
    lang.failedReturns, lang.failedRenews],
  datasets: [{
    backgroundColor: ['#1e2f8b', '#b92424', '#64d935', '#6c1a69', '#19baba', '#eca13d'],
    data: [counts.issues, counts.returns, counts.renews, counts.failedIssues,
      counts.failedReturns, counts.failedRenews]
  }]
})

export default class CountsChart extends Component<any,any> {

  render() {
    const { lang, counts } = this.props

    return (
      <Bar data={getData(lang, counts)} width={100} height={50} options={options} />
    )
  }
}
