/* @flow */

import React, { Component } from "react";

import {
  SettingsBox,
  SettingsTitle,
  SettingsSubTitle,
  SettingsHr,
} from "../../StyledComponents/Settings";
import { Row, ColL, Col } from "../../StyledComponents/Grid";
import TextInput from "../Inputs/TextInput";
import NumberInput from "../Inputs/NumberInput";
import CheckBox from "../Inputs/CheckBox";
import Help from "../Help";

export default class ChipPin extends Component<any, any> {
  render() {
    const { settings, lang, onChange } = this.props;

    return (
      <div>
        <SettingsTitle>{lang.settings.chippin}</SettingsTitle>
        {/* <SettingsSubTitle>{lang.settings.yespay}</SettingsSubTitle>
        <SettingsBox>
          <Row>
            <ColL size={2}>{lang.settings.easyvPort}</ColL>
            <ColL size={2}>
              <NumberInput
                path={["chippin", "easyvPort"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "easyvPort"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>{lang.settings.easyvReceiptPort}</ColL>
            <ColL size={2}>
              <NumberInput
                path={["chippin", "easyvReceiptPort"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "easyvReceiptPort"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>{lang.settings.minCardPayment}</ColL>
            <ColL size={2}>
              <NumberInput
                path={["chippin", "minCardPayment"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "minCardPayment"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <SettingsHr />
        </SettingsBox> */}
        <SettingsSubTitle>{lang.settings.payment3}</SettingsSubTitle>
        <SettingsBox>
          <Row>
            <ColL size={2}>
              <label htmlFor="enable3payment">
                {lang.settings.enable3payment}
                <Help attr="chippin.enable3payment" />
              </label>
            </ColL>
            <ColL size={2}>
              <CheckBox
                path={["chippin", "enable3payment"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "enable3payment"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.hostname}
              <Help attr="chippin.payment3Hostname" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["chippin", "payment3Hostname"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "payment3Hostname"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.port}
              <Help attr="chippin.payment3Port" />
            </ColL>
            <ColL size={2}>
              <NumberInput
                path={["chippin", "payment3Port"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "payment3Port"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.user}
              <Help attr="chippin.payment3User" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["chippin", "payment3User"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "payment3User"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.pwd}
              <Help attr="chippin.payment3Pwd" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="password"
                path={["chippin", "payment3Pwd"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "payment3Pwd"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              <label htmlFor="payment3SSLEnabled">
                {lang.settings.SSLEnabled}
                <Help attr="chippin.payment3SSLEnabled" />
              </label>
            </ColL>
            <ColL size={2}>
              <CheckBox
                path={["chippin", "payment3SSLEnabled"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "payment3SSLEnabled"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              <label htmlFor="payment3ValidateSSL">
                {lang.settings.validateSSL}
                <Help attr="chippin.payment3ValidateSSL" />
              </label>
            </ColL>
            <ColL size={2}>
              <CheckBox
                path={["chippin", "payment3ValidateSSL"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "payment3ValidateSSL"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <SettingsHr />
        </SettingsBox>
        <SettingsSubTitle>{lang.settings.creditCall}</SettingsSubTitle>
        <SettingsBox>
          <Row>
            <ColL size={2}>
              {lang.settings.terminalId}
              <Help attr="chippin.terminalId" />
            </ColL>
            <ColL size={2}>
              <NumberInput
                path={["chippin", "terminalId"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "terminalId"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.serverAddress}
              <Help attr="chippin.serverAddress" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["chippin", "serverAddress"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "serverAddress"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.port}
              <Help attr="chippin.port" />
            </ColL>
            <ColL size={2}>
              <NumberInput
                path={["chippin", "port"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "port"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <SettingsHr />
        </SettingsBox>
        <SettingsSubTitle>{lang.settings.capita}</SettingsSubTitle>
        <SettingsBox>
          <Row>
            <ColL size={2}>
              {lang.settings.smartClientIP}
              <Help attr="chippin.smartClientIP" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["chippin", "smartClientIP"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "smartClientIP"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.smartClientPort}
              <Help attr="chippin.smartClientPort" />
            </ColL>
            <ColL size={2}>
              <NumberInput
                path={["chippin", "smartClientPort"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "smartClientPort"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.machineCode}
              <Help attr="chippin.machineCode" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["chippin", "machineCode"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "machineCode"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.siteID}
              <Help attr="chippin.siteID" />
            </ColL>
            <ColL size={2}>
              <NumberInput
                path={["chippin", "siteID"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "siteID"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.userCode}
              <Help attr="chippin.userCode" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["chippin", "userCode"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "userCode"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.fundCode}
              <Help attr="chippin.fundCode" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["chippin", "fundCode"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "fundCode"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
        </SettingsBox>
      </div>
    );
  }
}
