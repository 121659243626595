/* @flow */

import { Map, fromJS } from "immutable";
import {
  UI_SET,
  UI_UNSET,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,
  GET_SETTINGS_START,
  GET_THEMES_START,
  GET_THEMES_SUCCESS,
  GET_THEMES_FAIL,
} from "../constants/actions";
import { LOCATION_CHANGE } from "react-router-redux";

export default (state: IMap = new Map(), action: Action) => {
  switch (action.type) {
    case UI_SET: {
      const { path, value } = action.payload;
      return state.setIn(path, fromJS(value));
    }

    case UI_UNSET: {
      const { path } = action.payload;
      return state.deleteIn(path);
    }

    case LOCATION_CHANGE: {
      return new Map();
    }

    case GET_SETTINGS_START: {
      return state.setIn(["loadingSettings"], true);
    }

    case GET_THEMES_START: {
      return state.setIn(["loadingTheme"], true);
    }

    case GET_SETTINGS_SUCCESS:
    case GET_SETTINGS_FAIL: {
      return state.deleteIn(["loadingSettings"]);
    }

    case GET_THEMES_SUCCESS:
    case GET_THEMES_FAIL: {
      return state.deleteIn(["loadingTheme"]);
    }

    default:
      return state;
  }
};
