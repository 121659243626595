/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";

import { startLogin } from "../../Data/actionCreators";
import { getUI, getLang } from "../../Data/selectors";
import { LOGIN_PREFIX } from "../../Data/constants/actions";

import { Container, Body, Footer } from "../StyledComponents/Page";
import { FormGroup, LoginForm, Btn } from "../StyledComponents/Forms";
import StatusMessage from "../Components/StatusMessage";

export class Login extends Component<any, any> {
  state = { username: "", password: "" };

  change = (key: string) => (e: Event) => {
    this.setState({ [key]: e.target.value });
  };

  submit = (e: Event) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const { username, password } = this.state;
    dispatch(startLogin(username, password));
  };

  render() {
    const { ui, lang } = this.props;
    const { username, password } = this.state;

    return (
      <Container>
        {ui.has(LOGIN_PREFIX) ? (
          <StatusMessage lang={lang} object={ui.get(LOGIN_PREFIX)} />
        ) : null}
        <Body>
          <LoginForm onSubmit={this.submit}>
            <h1>Welcome to ManageIT</h1>
            <FormGroup>
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={this.change("username")}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={this.change("password")}
              />
            </FormGroup>
            <Btn type="submit">{lang.login}</Btn>
          </LoginForm>
        </Body>
        <Footer />
      </Container>
    );
  }
}

export default connect((state: State) => ({
  ui: getUI(state),
  lang: getLang(state),
}))(Login);
