/* @flow */

import React, { Component } from "react";

import { SettingsBox, SettingsTitle } from "../../StyledComponents/Settings";
import { Row, ColL, Col } from "../../StyledComponents/Grid";
import TextInput from "../Inputs/TextInput";
import NumberInput from "../Inputs/NumberInput";
import CheckBox from "../Inputs/CheckBox";
import Help from "../Help";

export default class UserCard extends Component<any, any> {
  render() {
    const { settings, lang, onChange } = this.props;

    return (
      <div>
        <SettingsTitle>{lang.settings.userCard}</SettingsTitle>
        <SettingsBox>
          <Row>
            <ColL size={2}>
              {lang.settings.maskingField1}
              <Help attr="userCard.maskingField1" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["userCard", "maskingField1"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <ColL size={1}>
              <NumberInput
                placeholder={lang.settings.noChars}
                path={["userCard", "nochars1"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "maskingField1:nochars1"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.maskingField2}
              <Help attr="userCard.maskingField2" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["userCard", "maskingField2"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <ColL size={1}>
              <NumberInput
                placeholder={lang.settings.noChars}
                path={["userCard", "nochars2"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "maskingField2:nochars2"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.maskingField3}
              <Help attr="userCard.maskingField3" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["userCard", "maskingField3"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <ColL size={1}>
              <NumberInput
                placeholder={lang.settings.noChars}
                path={["userCard", "nochars3"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "maskingField3:nochars3"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
        </SettingsBox>
      </div>
    );
  }
}
