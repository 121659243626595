import { all } from 'redux-saga/effects'

import actionToRequest from './actionToRequest'
import request from './request'
import router from './router'
import updates from './updates'
import misc from './misc'
import triggerPrint from './triggerPrint'


export default function* rootSaga() {
  yield all([
    actionToRequest(),
    request(),
    router(),
    updates(),
    misc(),
    triggerPrint(),
  ])
}
