/* @flow */

import React, { Component } from "react";

import { SettingsBox, SettingsTitle } from "../../StyledComponents/Settings";
import { Row, ColL, Col } from "../../StyledComponents/Grid";
import TextAreaInput from "../Inputs/TextAreaInput";
import CheckBox from "../Inputs/CheckBox";
import Radio from "../Inputs/Radio";
import Help from "../Help";

export default class Receipts extends Component<any, any> {
  render() {
    const { settings, lang, onChange } = this.props;

    return (
      <div>
        <SettingsTitle>{lang.settings.receipts}</SettingsTitle>
        <SettingsBox>
          <Row>
            <ColL bold={true} size={2}>
              {lang.settings.header}
              <Help attr="receipts.header" />
            </ColL>
            <ColL size={2}>
              <TextAreaInput
                type="text"
                path={["receipts", "header"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "header"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL bold={true} size={2}>
              {lang.settings.footer}
              <Help attr="receipts.footer" />
            </ColL>
            <ColL size={2}>
              <TextAreaInput
                type="text"
                path={["receipts", "footer"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "footer"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
        </SettingsBox>
        <SettingsTitle>{lang.settings.print}</SettingsTitle>
        <SettingsBox>
          <Row>
            <ColL size={5}>
              <label htmlFor="printOp1">
                {lang.settings.printOp1}
                <Help attr="print.printOp1" />
              </label>
            </ColL>
            <Col size={1}>
              <CheckBox
                style={{ margin: "auto" }}
                path={["receipts", "printOp1"]}
                obj={settings}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "printOp1"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={5}>
              <label htmlFor="printOp2">
                {lang.settings.printOp2}
                <Help attr="print.printOp2" />
              </label>
            </ColL>
            <Col size={1}>
              <CheckBox
                style={{ margin: "auto" }}
                path={["receipts", "printOp2"]}
                obj={settings}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "printOp2"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={5}>
              <label htmlFor="printOp3">
                {lang.settings.printOp3}
                <Help attr="print.printOp3" />
              </label>
            </ColL>
            <Col size={1}>
              <CheckBox
                style={{ margin: "auto" }}
                path={["receipts", "printOp3"]}
                obj={settings}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "printOp3"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={5}>
              <label htmlFor="printOp4">
                {lang.settings.printOp4}
                <Help attr="print.printOp4" />
              </label>
            </ColL>
            <Col size={1}>
              <CheckBox
                style={{ margin: "auto" }}
                path={["receipts", "printOp4"]}
                obj={settings}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "printOp4"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={5}>
              <label htmlFor="printOp5">
                {lang.settings.printOp5}
                <Help attr="print.printOp5" />
              </label>
            </ColL>
            <Col size={1}>
              <CheckBox
                style={{ margin: "auto" }}
                path={["receipts", "printOp5"]}
                obj={settings}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "printOp5"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={5}>
              <label htmlFor="printOp6">
                {lang.settings.printOp6}
                <Help attr="print.printOp6" />
              </label>
            </ColL>
            <Col size={1}>
              <CheckBox
                style={{ margin: "auto" }}
                path={["receipts", "printOp6"]}
                obj={settings}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "printOp6"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={5}>
              <label htmlFor="printOp7">
                {lang.settings.printOp7}
                <Help attr="print.printOp7" />
              </label>
            </ColL>
            <Col size={1}>
              <CheckBox
                style={{ margin: "auto" }}
                path={["receipts", "printOp7"]}
                obj={settings}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "printOp7"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={1}>
              {lang.settings.printIdType}
              <Help attr="print.printIdType" />
            </ColL>
            <Col size={2}>
              <label>
                <Radio
                  name="printIdType"
                  obj={settings}
                  onChange={onChange}
                  path={["receipts", "printIdType"]}
                  value="patronName"
                />{" "}
                {lang.settings.patronName}
              </label>
            </Col>
            <Col size={3}>
              <label>
                <Radio
                  name="printIdType"
                  obj={settings}
                  onChange={onChange}
                  path={["receipts", "printIdType"]}
                  value="patronId"
                />{" "}
                {lang.settings.patronId}
              </label>
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "printIdType"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
        </SettingsBox>
        <SettingsTitle style={{ marginTop: 20 }}>
          {lang.settings.emailReceipts}
        </SettingsTitle>
        <SettingsBox>
          <Row>
            <ColL size={5}>
              <label htmlFor="emailReceiptOp1">
                {lang.settings.emailReceiptOp1}
                <Help attr="emailreceipts.emailReceiptOp1" />
              </label>
            </ColL>
            <Col size={1}>
              <CheckBox
                style={{ margin: "auto" }}
                path={["receipts", "emailReceiptOp1"]}
                obj={settings}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "emailReceiptOp1"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={5}>
              <label htmlFor="emailReceiptOp2">
                {lang.settings.emailReceiptOp2}
                <Help attr="emailreceipts.emailReceiptOp2" />
              </label>
            </ColL>
            <Col size={1}>
              <CheckBox
                style={{ margin: "auto" }}
                path={["receipts", "emailReceiptOp2"]}
                obj={settings}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "emailReceiptOp2"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={5}>
              <label htmlFor="emailReceiptOp3">
                {lang.settings.emailReceiptOp3}
                <Help attr="emailreceipts.emailReceiptOp3" />
              </label>
            </ColL>
            <Col size={1}>
              <CheckBox
                style={{ margin: "auto" }}
                path={["receipts", "emailReceiptOp3"]}
                obj={settings}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "emailReceiptOp3"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={5}>
              <label htmlFor="emailReceiptOp4">
                {lang.settings.emailReceiptOp4}
                <Help attr="emailreceipts.emailReceiptOp4" />
              </label>
            </ColL>
            <Col size={1}>
              <CheckBox
                style={{ margin: "auto" }}
                path={["receipts", "emailReceiptOp4"]}
                obj={settings}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "emailReceiptOp4"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={5}>
              <label htmlFor="emailReceiptOp5">
                {lang.settings.emailReceiptOp5}
                <Help attr="emailreceipts.emailReceiptOp5" />
              </label>
            </ColL>
            <Col size={1}>
              <CheckBox
                style={{ margin: "auto" }}
                path={["receipts", "emailReceiptOp5"]}
                obj={settings}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "emailReceiptOp5"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={5}>
              <label htmlFor="emailReceiptOp6">
                {lang.settings.emailReceiptOp6}
                <Help attr="emailreceipts.emailReceiptOp6" />
              </label>
            </ColL>
            <Col size={1}>
              <CheckBox
                style={{ margin: "auto" }}
                path={["receipts", "emailReceiptOp6"]}
                obj={settings}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "emailReceiptOp6"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={5}>
              <label htmlFor="emailReceiptOp7">
                {lang.settings.emailReceiptOp7}
                <Help attr="emailreceipts.emailReceiptOp7" />
              </label>
            </ColL>
            <Col size={1}>
              <CheckBox
                style={{ margin: "auto" }}
                path={["receipts", "emailReceiptOp7"]}
                obj={settings}
                onChange={onChange}
              />
            </Col>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "emailReceiptOp7"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
        </SettingsBox>
      </div>
    );
  }
}
