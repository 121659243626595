/* @flow */

import React, { Component } from "react";

import { TextArea } from "../../StyledComponents/Forms";
import last from "lodash/last";

export default class TextAreaInput extends Component<any, any> {
  onChange = (path: Array<string>) => (e: Event) => {
    const { onChange } = this.props;
    onChange(path)(e.target.value);
  };

  render() {
    const { type, obj, path, onChange, ...rest } = this.props;
    const id = last(path);

    return (
      <TextArea
        type={type}
        id={id}
        onChange={this.onChange(path)}
        value={obj.getIn(path, "")}
        {...rest}
      />
    );
  }
}
