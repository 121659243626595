/* @flow */

import React, { Component } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import FA from "react-fontawesome";
import { Btn, DateHead, DateContainer } from "../../StyledComponents/Forms";

export default class DateInput extends Component<any, any> {
  state = { from: null, to: null, open: false };

  componentWillReceiveProps = (nextProps: any) => {
    const { from, to, disabled } = nextProps;
    if (from || to) this.setState({ from, to });
    if (disabled !== this.props.disabled && disabled) {
      this.setState({ open: false });
    }
  };

  onChange = (day: any) => {
    const { onChange } = this.props;
    const range = DateUtils.addDayToRange(day, this.state);
    const { from, to } = range;
    if (from && to) this.setState({ ...range, open: false });
    else this.setState(range);
    if (onChange) onChange(range);
  };

  reset = (e: any) => {
    e.stopPropagation();
    this.setState({ from: null, to: null, open: false });
  };

  toggle = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  render() {
    const { lang, disabled } = this.props;
    const { from, to, open } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <DateContainer>
        <DateHead onClick={this.toggle}>
          <FA name="calendar" size="2x" />
          <div>
            {!from && lang.selectStartDate}
            {from && !to && lang.selectLastDate}
            {from && to ? (
              <React.Fragment>
                {lang.selected(
                  from.toLocaleDateString(),
                  to.toLocaleDateString()
                )}
                <Btn
                  icon={1}
                  danger={1}
                  tiny={1}
                  onClick={this.reset}
                  style={{ marginLeft: 10 }}
                >
                  <FA name="remove" />
                </Btn>
              </React.Fragment>
            ) : null}
          </div>
        </DateHead>
        {open && !disabled ? (
          <DayPicker
            className="DateInput"
            numberOfMonths={2}
            selectedDays={[from, { from, to }]}
            modifiers={modifiers}
            onDayClick={this.onChange}
          />
        ) : null}
      </DateContainer>
    );
  }
}
