/* @flow */

import styled from 'styled-components'


export const SettingsBox = styled.div`
  margin: 0 30px;
  font-size: 20px;

  & * {
    font-size: 20px;
  }
`;

export const SettingsTitle = styled.h1`
  margin-bottom: 30px;
  margin-left: 30px;
`;

export const SettingsSubTitle = styled.h3`
  margin-bottom: 30px;
  margin-left: 30px;
`;

export const SettingsHr = styled.hr`
  margin-top: 30px;
  margin-bottom: 30px;
  border: none;
  border-top: 1px solid #ccc;
`;
