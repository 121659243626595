/* @flow */

import { Map, fromJS } from "immutable";
import {
  GET_SETTINGS_START,
  SET_SETTINGS,
  GET_SETTINGS_SUCCESS,
} from "../constants/actions";

export default (state: IMap = new Map(), action: Action) => {
  switch (action.type) {
    case GET_SETTINGS_START:
      return new Map();

    case GET_SETTINGS_SUCCESS: {
      const { settings } = action.payload;
      return fromJS(settings);
    }

    case SET_SETTINGS: {
      const { value, path } = action.payload;
      return state.setIn(path, value);
    }

    default:
      return state;
  }
};
