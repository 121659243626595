/* @flow */

import { List, fromJS } from 'immutable'
import { SET_LANGAUGES, GET_LANGUAGES_SUCCESS } from '../constants/actions'


export default (state: IList = new List(), action: Action) => {

  switch (action.type) {

    case GET_LANGUAGES_SUCCESS: {
      const { languages } = action.payload
      return fromJS(languages)
    }

    case SET_LANGAUGES: {
      const { languages } = action.payload
      return languages
    }

    default:
      return state
  }
}
