/* @flow */

import { Map, fromJS } from 'immutable'
import { GET_COUNTS_SUCCESS } from '../constants/actions'

export default (state: IMap = new Map(), action: Action) => {

  switch (action.type) {

    case GET_COUNTS_SUCCESS: {
      const { counts } = action.payload
      return fromJS(counts)
    }

    default:
      return state
  }
}
