/* @flow */

import styled from "styled-components";

export const FormGroup = styled.div`
  margin: 10px 0;
  width: 100%;

  & label {
    display: block;
    font-weight: bold;
  }

  & input[type="text"],
  & input[type="email"],
  & input[type="password"],
  & input[type="number"],
  & textarea {
    font-size: 18px;
    display: block;
    width: 100%;
    padding: 5px;
    border: 2px solid #d9d9d9;
    outline: none;

    &:focus {
      border: 2px solid #a1a1a1;
    }
  }
`;

export const PasswordContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PwdBtn = styled.div`
  flex-shrink: 1;
  cursor: pointer;
  background: #d9d9d9;
  color: #1c3a78;
  font-weight: bold;
  width: 35px;
  display: flex;

  & .fa {
    font-size: 20px;
    margin: auto;
  }
`;

export const SelectContainer = styled.div`
  border: 2px solid #d9d9d9;
  border-radius: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 34px;
  position: relative;

  & select {
    font-size: 18px;
    background: transparent;
    border: none;
    -webkit-appearance: none;
    outline: none;
    height: 30px;
    width: 100%;
    padding: 2px;
    padding-left: 5px;
    position: absolute;
    top: 0;
    z-index: 999;
  }
`;

export const SelectBtn = styled.div`
  height: 30px;
  width: 30px;
  background: #d9d9d9;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #1c3a78;
    position: relative;
    top: 12px;
    left: 8px;
  }
`;

export const Btn = styled.button`
  position: relative;
  display: inline-block;
  padding: ${(props) => (props.tiny ? "5px 10px" : "10px 20px")};
  flex-shrink: 1;
  text-align: center;
  border-radius: 2px;
  font-size: 20px;
  line-height: 22px;
  cursor: pointer;
  outline: none;
  background: ${(props) =>
    props.danger ? "#d74e4e" : props.primary ? "#1854a4" : "#d0d0d0"};
  color: ${(props) => (props.danger || props.primary ? "#fff" : "#000")};
  border: ${(props) =>
    props.danger
      ? "1px solid #e28e8e"
      : props.primary
      ? "1px solid #114182"
      : "1px solid #d4cfcf"};
  box-shadow: ${(props) =>
    props.danger
      ? "0 2px 0 #782020"
      : props.primary
      ? "0 2px 0 #113666"
      : "0 2px 0 #8e8e8e"};
  font-weight: bold;

  & .fa {
    margin-right: ${(props) => (props.icon ? "0px" : "5px")};
    font-size: 22px;
  }

  &:active {
    top: 1px;
    box-shadow: ${(props) =>
      props.primary ? "0 1px 0 #113666" : "0 1px 0 #8e8e8e"};
  }
`;

export const LoginForm = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;
`;

export const Input = styled.input`
  width: 100%;
  outline: none;
  border: 1px solid #ccc;
  padding: 5px;

  &:focus {
    border: 1px solid #a8a7a7;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  outline: none;
  border: 1px solid #ccc;
  padding: 5px;

  &:focus {
    border: 1px solid #a8a7a7;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  }
`;

export const DateContainer = styled.div`
  position: relative;
  width: 100%;

  .DateInput {
    position: absolute;
    z-index: 999;
    background: #fff;
    border: 1px solid #eee;
    left: 10px;
    box-shadow: 0 8px 20px 0 #dcdcdc;
  }
`;

export const DateHead = styled.div`
  font-weight: bold;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: row;

  & > * {
    margin: auto;
  }

  & > div {
    margin-left: 10px;
  }

  & > .fa {
    margin: auto 10px auto 10px;
  }
`;
