/* @flow */

import React, { Component } from 'react'

import { ColorBtn } from '../../StyledComponents/Main'
import { ChromePicker } from 'react-color'


export default class ColorInput extends Component<any,any> {

  state = { displayColorPicker: false }

  togglePicker = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  handleChange = (color: {hex: string}) => {
    const { path, onChange } = this.props
    onChange(path)(color.hex)
  }

  render() {
    const { obj, path } = this.props
    const color = obj.getIn(path)

    const popover = {
      position: 'absolute',
      zIndex: '9999',
      left: '-95px'
    }

    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

    return (
      <div style={{position: 'relative'}}>
        <ColorBtn color={color} onClick={ this.togglePicker } />
        { this.state.displayColorPicker ?
          <div style={ popover }>
            <div style={ cover } onClick={ this.handleClose }/>
            <ChromePicker color={color} onChange={ this.handleChange } />
          </div>
        : null }
      </div>
    )
  }
}
