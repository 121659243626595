/* @flow */

import { LOGIN_SUCCESS, DO_LOGIN, CLEAR_ACCOUNT } from '../constants/actions'


export default (state: Object = {}, action: Action) => {

  switch (action.type) {

    case DO_LOGIN:
    case LOGIN_SUCCESS: {
      const { token, userType } = action.payload
      return { token, userType: parseInt(userType, 10) }
    }

    case CLEAR_ACCOUNT: {
      return {}
    }

    default:
      return state
  }
}
