/* @flow */

import { List, fromJS } from 'immutable'
import { GET_USERS_SUCCESS, DELETE_USERS_SUCCESS } from '../constants/actions'


export default (state: IList = new List(), action: Action) => {

  switch (action.type) {

    case GET_USERS_SUCCESS: {
      const { users } = action.payload
      return fromJS(users)
    }

    case DELETE_USERS_SUCCESS: {
      const { id } = action.payload
      return state.filter(e => e.get('id') === id)
    }

    default:
      return state
  }
}
