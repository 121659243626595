/* @flow */

import React, { Component } from "react";

import { SettingsBox, SettingsTitle } from "../../StyledComponents/Settings";
import { Row, ColL, Col } from "../../StyledComponents/Grid";
import SelectInput from "../Inputs/SelectInput";
import CheckBox from "../Inputs/CheckBox";
import Help from "../Help";

export default class EMDevice extends Component<any, any> {
  render() {
    const { settings, lang, onChange } = this.props;

    return (
      <div>
        <SettingsTitle>{lang.settings.EMDevice}</SettingsTitle>
        <SettingsBox>
          <Row>
            <ColL size={2}>
              {lang.settings.portName}
              <Help attr="EMDevice.portName" />
            </ColL>
            <ColL size={2}>
              <SelectInput
                options={["", "COM1"]}
                path={["EMDevice", "portName"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "portName"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.baudRate}
              <Help attr="EMDevice.baudRate" />
            </ColL>
            <ColL size={2}>
              <SelectInput
                options={["", "1200"]}
                path={["EMDevice", "baudRate"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "baudRate"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.parity}
              <Help attr="EMDevice.parity" />
            </ColL>
            <ColL size={2}>
              <SelectInput
                options={["", "None"]}
                path={["EMDevice", "parity"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "parity"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.dataBits}
              <Help attr="EMDevice.dataBits" />
            </ColL>
            <ColL size={2}>
              <SelectInput
                options={["", "8"]}
                path={["EMDevice", "dataBits"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "dataBits"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.stopBits}
              <Help attr="EMDevice.stopBits" />
            </ColL>
            <ColL size={2}>
              <SelectInput
                options={["", "1"]}
                path={["EMDevice", "stopBits"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "stopBits"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
        </SettingsBox>
      </div>
    );
  }
}
