/* @flow */

import { Map, fromJS } from "immutable";
import {
  SET_THEME,
  GET_THEMES_START,
  GET_THEMES_SUCCESS,
} from "../constants/actions";

export default (state: IMap = new Map(), action: Action) => {
  switch (action.type) {
    case GET_THEMES_START:
      return new Map();

    case GET_THEMES_SUCCESS: {
      const { theme } = action.payload;
      return fromJS(theme);
    }

    case SET_THEME: {
      const { value, path } = action.payload;
      return state.setIn(path, value);
    }

    default:
      return state;
  }
};
