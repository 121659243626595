/* @flow */

import React, { Component } from "react";

import { SettingsBox, SettingsTitle } from "../../StyledComponents/Settings";
import { Row, ColL, Col } from "../../StyledComponents/Grid";
import NumberInput from "../Inputs/NumberInput";
import CheckBox from "../Inputs/CheckBox";
import Help from "../Help";

export default class Timing extends Component<any, any> {
  render() {
    const { settings, lang, onChange } = this.props;

    return (
      <div>
        <SettingsTitle>{lang.settings.timing}</SettingsTitle>
        <SettingsBox>
          <Row>
            <ColL size={2}>
              {lang.settings.cardScanningTimeout}
              <Help attr="timing.cardScanningTimeout" />
            </ColL>
            <ColL size={2}>
              <NumberInput
                path={["timing", "cardScanningTimeout"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "cardScanningTimeout"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.LMSInterval}
              <Help attr="timing.LMSInterval" />
            </ColL>
            <ColL size={2}>
              <NumberInput
                path={["timing", "LMSInterval"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "LMSInterval"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.screenTimeout}
              <Help attr="timing.screenTimeout" />
            </ColL>
            <ColL size={2}>
              <NumberInput
                path={["timing", "screenTimeout"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "screenTimeout"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.warningTimeout}
              <Help attr="timing.warningTimeout" />
            </ColL>
            <ColL size={2}>
              <NumberInput
                path={["timing", "warningTimeout"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "warningTimeout"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
        </SettingsBox>
      </div>
    );
  }
}
