/* @flow */

import en from '../langs/en'

export default (state: string = en, action: Action) => {

  switch (action.type) {

    default:
      return state
  }
}
