/* @flow */

import styled from 'styled-components'

export const SegmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const Segment = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  margin: 5px;
`;

export const SegmentTitle = styled.div`
  padding: 10px;
  text-align: center;
  font-weight: bold;
  background: #5a91b8;
  color: #fff;
`;

export const SegmentRows = styled.div`
  border: 2px solid #5a91b8;
`;

export const SegmentRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
`;

export const SegmentCol = styled.div`
  flex: 1;
  padding: 5px;
  font-weight: ${props => props.primary ? 'bold' : 'normal'};
  text-align: ${props => props.primary ? 'left' : 'right'};
`;

export const SegmentBottom = styled.div`
  box-sizing: border-box;
  padding: 5px;
  width: 100%;
  margin-top: auto;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
`;

export const SegmentBlank = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 5px;
`;
