/* @flow */

import React, { Component } from 'react'

import { PasswordContainer, Input, PwdBtn } from '../../StyledComponents/Forms'
import FA from 'react-fontawesome'
import last from 'lodash/last'
import randomstring from 'randomstring'


export default class PasswordInput extends Component<any,any> {

  state = { show: false }

  onChange = (path: Array<string>) => (e: Event) => {
    const { onChange } = this.props
    onChange(path)(e.target.value)
  }

  generate = (path: Array<string>) => (e: Event) => {
    const { onChange } = this.props
    const newVal = randomstring.generate({
      length: 16,
      charset: 'alphanumeric'
    })
    onChange(path)(newVal)
  }

  toggle = () => {
    this.setState({ show: !this.state.show })
  }

  render() {
    const { obj, path, onChange, ...rest } = this.props
    const { show } = this.state
    const id = last(path)

    return (
      <PasswordContainer>
        <Input type={ show ? 'text' : 'password' } id={id} onChange={this.onChange(path)} value={obj.getIn(path, '')} {...rest} />
        <PwdBtn onClick={this.toggle}>
          { show ? <FA name='eye-slash' /> : <FA name='eye' /> }</PwdBtn>
        <PwdBtn onClick={this.generate(path)}><FA name='refresh' /></PwdBtn>
      </PasswordContainer>
    )
  }
}
