/* @flow */

import React, { Component } from "react";

import { SettingsBox, SettingsTitle } from "../../StyledComponents/Settings";
import { Row, ColL, Col } from "../../StyledComponents/Grid";
import TextInput from "../Inputs/TextInput";
import NumberInput from "../Inputs/NumberInput";
import CheckBox from "../Inputs/CheckBox";
import Help from "../Help";

export default class Money extends Component<any, any> {
  render() {
    const { settings, lang, onChange } = this.props;

    return (
      <div>
        <SettingsTitle>{lang.settings.money}</SettingsTitle>
        <SettingsBox>
          {/* <Row>
            <ColL size={2}>
              <label htmlFor="cacheBalance">{lang.settings.cacheBalance}</label>
              <Help attr="money.cacheBalance" />
            </ColL>
            <ColL size={2}>
              <CheckBox
                path={["money", "cacheBalance"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "cacheBalance"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row> */}
          <Row>
            <ColL size={2}>
              <label htmlFor="debitPositive">
                {lang.settings.debitPositive}
                <Help attr="money.debitPositive" />
              </label>
            </ColL>
            <ColL size={2}>
              <CheckBox
                path={["money", "debitPositive"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "debitPositive"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.maxDebit}
              <Help attr="money.maxDebit" />
            </ColL>
            <ColL size={2}>
              <NumberInput
                path={["money", "maxDebit"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "maxDebit"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.maxCredit}
              <Help attr="money.maxCredit" />
            </ColL>
            <ColL size={2}>
              <NumberInput
                path={["money", "maxCredit"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "maxCredit"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.currencySymbol}
              <Help attr="money.currencySymbol" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["money", "currencySymbol"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "currencySymbol"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              {lang.settings.currencyType}
              <Help attr="money.currencyType" />
            </ColL>
            <ColL size={2}>
              <TextInput
                type="text"
                path={["money", "currencyType"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "currencyType"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              <label htmlFor="borrowMaxDebit">
                {lang.settings.borrowMaxDebit}
                <Help attr="money.borrowMaxDebit" />
              </label>
            </ColL>
            <ColL size={2}>
              <CheckBox
                path={["money", "borrowMaxDebit"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "borrowMaxDebit"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              <label htmlFor="returnMaxDebit">
                {lang.settings.returnMaxDebit}
                <Help attr="money.returnMaxDebit" />
              </label>
            </ColL>
            <ColL size={2}>
              <CheckBox
                path={["money", "returnMaxDebit"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "returnMaxDebit"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              <label htmlFor="renewalsMaxDebit">
                {lang.settings.renewalsMaxDebit}
                <Help attr="money.renewalsMaxDebit" />
              </label>
            </ColL>
            <ColL size={2}>
              <CheckBox
                path={["money", "renewalsMaxDebit"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "renewalsMaxDebit"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              <label htmlFor="payMaxDebit">
                {lang.settings.payMaxDebit}
                <Help attr="money.payMaxDebit" />
              </label>
            </ColL>
            <ColL size={2}>
              <CheckBox
                path={["money", "payMaxDebit"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "payMaxDebit"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
          <Row>
            <ColL size={2}>
              <label htmlFor="reservationMaxDebit">
                {lang.settings.reservationMaxDebit}
                <Help attr="money.reservationMaxDebit" />
              </label>
            </ColL>
            <ColL size={2}>
              <CheckBox
                path={["money", "reservationMaxDebit"]}
                obj={settings}
                onChange={onChange}
              />
            </ColL>
            <Col bold={true} size={1}>
              <label>
                <CheckBox
                  style={{ marginRight: 10 }}
                  path={["__updates", "reservationMaxDebit"]}
                  obj={settings}
                  onChange={onChange}
                />
                Update
              </label>
            </Col>
          </Row>
        </SettingsBox>
      </div>
    );
  }
}
