/* @flow */

import React, { Component } from 'react'

import { Link } from 'react-router-dom'
import { Sidebar, SidebarItem } from '../StyledComponents/Main'
import FA from 'react-fontawesome'
import themeSchema from '../../Data/themeSchema'


export default class ThemeSidebar extends Component<any,any> {

  render() {
    const { lang, screen, id } = this.props

    return (
      <Sidebar>
        <Link to='/'>
          <SidebarItem main={true}>
            <FA name='caret-left' size='2x' /> <h2>{ lang.settings.back }</h2>
          </SidebarItem>
        </Link>
        { themeSchema.map(x =>
          <Link key={x.id} to={ '/theme/' + id + '/' + x.id }>
            <SidebarItem active={screen === x.id}>{ lang.theme[x.id] }</SidebarItem>
          </Link>) }
      </Sidebar>
    )
  }
}
