/* @flow */

import React, { Component } from 'react'

import last from 'lodash/last'


export default class Radio extends Component<any,any> {

  onChange = (path: Array<string>) => (e: Event) => {
    const { onChange, value } = this.props
    onChange(path)(value)
  }

  render() {
    const { obj, path, onChange, value, ...rest } = this.props
    const id = last(path)

    return (
      <input type='radio' id={id} onChange={this.onChange(path)} value={value} checked={obj.getIn(path) === value} {...rest} />
    )
  }
}
