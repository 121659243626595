/* @flow */

import React, { Component } from "react";
import { fromJS } from "immutable";
import FA from "react-fontawesome";
import { getConstantsFor } from "../../../Data/selectors";

import {
  SettingsBox,
  SettingsTitle,
  SettingsHr
} from "../../StyledComponents/Settings";
import { Btn } from "../../StyledComponents/Forms";
import { SelectContainer, SelectBtn } from "../../StyledComponents/Forms";
import { Row, Col, ColL } from "../../StyledComponents/Grid";
import TextAreaInput from "../Inputs/TextAreaInput";
import TextInput from "../Inputs/TextInput";

export default class Constants extends Component<any, any> {
  state = { language: "" };

  changeLanguage = (e: Event) => {
    const { onChange } = this.props;
    this.setState({ language: e.target.value });
    onChange(["__updates"])(false);
  };

  addConstant = () => {
    const { onChange, settings } = this.props;
    const { language } = this.state;
    const constants = getConstantsFor(settings, language);
    const newConstants = constants.push(fromJS({ name: "", value: "" }));
    onChange(["constants", language])(newConstants);
  };

  addConstantsFromFile = e => {
    const { onChange, settings } = this.props;
    const { language } = this.state;
    const constants = getConstantsFor(settings, language);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = e => {
      const text = e.target.result;
      const lines = text
        .split("\n")
        .filter(e => e)
        .map(line => {
          const parts = line.split("|");
          return fromJS({ name: parts[0], value: parts[1] });
        });
      const newConstants = constants.concat(lines);
      onChange(["constants", language])(newConstants);
    };
    reader.readAsText(file);
  };

  deleteConstant = (i: number) => () => {
    const { onChange, settings } = this.props;
    const { language } = this.state;
    const constants = getConstantsFor(settings, language);
    const newConstants = constants.delete(i);
    onChange(["constants", language])(newConstants);
  };

  render() {
    const { settings, lang, languages, onChange } = this.props;
    const { language } = this.state;

    return (
      <div>
        <SettingsTitle>{lang.settings.constants}</SettingsTitle>
        <SettingsBox>
          <Row>
            <ColL size={2}>{lang.language}</ColL>
            <Col size={2}>
              <SelectContainer>
                <select onChange={this.changeLanguage}>
                  <option value="" />
                  {languages.map(x => (
                    <option key={x.get("culture")} value={x.get("culture")}>
                      {x.get("culture")} - {x.get("friendlyName")}
                    </option>
                  ))}
                </select>
                <SelectBtn />
              </SelectContainer>
            </Col>
          </Row>
          <SettingsHr />
          {this.state.language ? (
            <div>
              {getConstantsFor(settings, language).map((x, i) => (
                <Row key={i} tiny={1}>
                  <Col size={2}>
                    <TextInput
                      type="text"
                      placeholder={lang.cstName}
                      obj={settings}
                      path={["constants", language, i, "name"]}
                      onChange={onChange}
                    />
                  </Col>
                  <Col size={2}>
                    <TextAreaInput
                      placeholder={lang.cstValue}
                      obj={settings}
                      path={["constants", language, i, "value"]}
                      onChange={onChange}
                    />
                  </Col>
                  <Col size={1}>
                    <Btn
                      icon={1}
                      danger={1}
                      tiny={1}
                      onClick={this.deleteConstant(i)}
                    >
                      <FA name="remove" />
                    </Btn>
                  </Col>
                </Row>
              ))}
              <Btn onClick={this.addConstant}>
                <FA name="plus" /> {lang.addConstant}
              </Btn>
              <label htmlFor="fileInput">
                <Btn style={{ marginLeft: 10 }}>
                  <FA name="file" /> {lang.addConstantsFromFile}
                </Btn>
              </label>
              <input
                style={{ display: "none" }}
                type="file"
                id="fileInput"
                onChange={this.addConstantsFromFile}
              />
            </div>
          ) : null}
        </SettingsBox>
      </div>
    );
  }
}
