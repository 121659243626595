/* @flow */

import React, { Component } from "react";
import find from "lodash/find";

import themeSchema from "../../Data/themeSchema";

import {
  SettingsBox,
  SettingsTitle,
  SettingsHr,
} from "../StyledComponents/Settings";
import { Row, Col, ColL } from "../StyledComponents/Grid";
import { SelectContainer, SelectBtn } from "../StyledComponents/Forms";
import TextInput from "./Inputs/TextInput";
import NumberInput from "./Inputs/NumberInput";
import CheckBox from "./Inputs/CheckBox";
import ColorInput from "./Inputs/ColorInput";
import SelectInput from "./Inputs/SelectInput";
import ImgInput from "./Inputs/ImgInput";
import Help from "./Help";

export default class ThemeEditor extends Component<any, any> {
  state = { selected: "" };

  componentWillReceiveProps(nextProps: Object) {
    if (this.props.component !== nextProps.component)
      this.setState({ selected: "" });
  }

  changeSelected = (e: Event) => {
    this.setState({ selected: e.target.value });
  };

  render() {
    const { theme, lang, screen, component, onChange } = this.props;

    const cat = find(themeSchema, (x) => x.id === screen);
    const subCat = find(cat.sub, (x) => x.id === component);
    const elements = subCat ? subCat.elements : [];
    const slectedComponent = find(
      elements,
      (x) => x.id === this.state.selected
    );

    return (
      <div>
        <SettingsTitle>{lang.theme[component]}</SettingsTitle>
        <SettingsBox>
          <Row>
            <Col size={2}>
              <SelectContainer>
                <select onChange={this.changeSelected}>
                  <option></option>
                  {elements.map((x) => (
                    <option key={x.id} value={x.id}>
                      {lang.theme[x.id]}
                    </option>
                  ))}
                </select>
                <SelectBtn />
              </SelectContainer>
            </Col>
          </Row>
          <SettingsHr />
          {slectedComponent
            ? slectedComponent.properties.map((x) => (
                <Row key={slectedComponent.id + "-" + x.id}>
                  <ColL size={2}>
                    {lang.theme[x.id]}
                    <Help attr={"theme." + x.id} />
                  </ColL>
                  <Col size={2}>
                    {x.type === "color" ? (
                      <ColorInput
                        obj={theme}
                        path={[
                          "theme",
                          screen,
                          component,
                          slectedComponent.id,
                          x.id,
                        ]}
                        onChange={onChange}
                      />
                    ) : x.type === "text" ? (
                      <TextInput
                        type="text"
                        obj={theme}
                        path={[
                          "theme",
                          screen,
                          component,
                          slectedComponent.id,
                          x.id,
                        ]}
                        onChange={onChange}
                      />
                    ) : x.type === "number" ? (
                      <NumberInput
                        obj={theme}
                        path={[
                          "theme",
                          screen,
                          component,
                          slectedComponent.id,
                          x.id,
                        ]}
                        onChange={onChange}
                      />
                    ) : x.type === "weight" ? (
                      <SelectInput
                        options={["Normal", "Bold", "Bolder", "Lighter"]}
                        obj={theme}
                        path={[
                          "theme",
                          screen,
                          component,
                          slectedComponent.id,
                          x.id,
                        ]}
                        onChange={onChange}
                      />
                    ) : x.type === "halign" ? (
                      <SelectInput
                        options={["Left", "Center", "Right"]}
                        obj={theme}
                        path={[
                          "theme",
                          screen,
                          component,
                          slectedComponent.id,
                          x.id,
                        ]}
                        onChange={onChange}
                      />
                    ) : x.type === "valign" ? (
                      <SelectInput
                        options={["Top", "Center", "Bottom"]}
                        obj={theme}
                        path={[
                          "theme",
                          screen,
                          component,
                          slectedComponent.id,
                          x.id,
                        ]}
                        onChange={onChange}
                      />
                    ) : x.type === "checkbox" ? (
                      <CheckBox
                        obj={theme}
                        path={[
                          "theme",
                          screen,
                          component,
                          slectedComponent.id,
                          x.id,
                        ]}
                        onChange={onChange}
                      />
                    ) : x.type === "image" ? (
                      <ImgInput
                        obj={theme}
                        path={[
                          "theme",
                          screen,
                          component,
                          slectedComponent.id,
                          x.id,
                        ]}
                        onChange={onChange}
                      />
                    ) : null}
                  </Col>
                  <Col bold={true} size={1}>
                    <label>
                      <CheckBox
                        style={{ marginRight: 10 }}
                        path={[
                          "__updates",
                          screen,
                          component,
                          slectedComponent.id,
                          x.id,
                        ]}
                        obj={theme}
                        onChange={onChange}
                      />
                      Update
                    </label>
                  </Col>
                </Row>
              ))
            : null}
        </SettingsBox>
      </div>
    );
  }
}
