import { takeLatest, all, put, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import {
  INIT,
  LOGIN_SUCCESS,
  DO_LOGIN,
  DO_LOGOUT,
  DELETE_SC_SUCCESS,
  DELETE_LIB_SUCCESS,
} from "../constants/actions";
import {
  getAccount,
  getRouting,
  getRoute,
  getLibraries,
  getLang,
} from "../selectors";
import { push, LOCATION_CHANGE } from "react-router-redux";
import { startGetLibraries, clearAccount } from "../actionCreators";

const DTECH_TOKEN = "DTECH_TOKEN";
const DTECH_USER_TYPE = "DTECH_USER_TYPE";

function* redirectHome(action) {
  const { token, userType } = action.payload;
  localStorage.setItem(DTECH_TOKEN, token);
  localStorage.setItem(DTECH_USER_TYPE, userType);
  yield put(push("/"));
}

function* authMiddleware() {
  const token = localStorage.getItem(DTECH_TOKEN);
  const userType = localStorage.getItem(DTECH_USER_TYPE);
  const account = yield select(getAccount);
  if (!account.token) {
    if (token && userType)
      yield put({ type: DO_LOGIN, payload: { token, userType } });
  }
}

function* loadLibsPeriodically() {
  yield delay(60 * 1000);
  while (true) {
    const account = yield select(getAccount);
    if (!account.token) {
      yield put(push("/login"));
    } else {
      yield put(startGetLibraries());
    }
    yield delay(30 * 1000);
  }
}

function* loadLibsMiddleware() {
  const libraries = yield select(getLibraries);
  if (libraries.isEmpty()) yield put(startGetLibraries());
}

function* router(action) {
  const { pathname } = action.payload;
  yield authMiddleware();

  if (pathname !== "/login") {
    const account = yield select(getAccount);
    if (!account.token) yield put(push("/login"));
    yield loadLibsMiddleware();
  }
}

function* logout(action) {
  const { ask } = action.payload;
  const lang = yield select(getLang);
  if (ask) {
    const res = window.confirm(lang.confirmLogout);
    if (res) {
      localStorage.clear();
      yield put(clearAccount());
      yield put(push("/login"));
    }
  } else {
    localStorage.clear();
    yield put(clearAccount());
    yield put(push("/login"));
  }
}

function* selfCheckDeleted(action) {
  const routing = yield select(getRouting);
  const { path } = getRoute(routing);
  if (path === "selfchecks") {
    yield put(push("/"));
  }
}

function* goHome(action) {
  yield put(push("/"));
}

function* saga() {
  yield all([
    takeLatest(INIT, loadLibsPeriodically),
    takeLatest(LOGIN_SUCCESS, redirectHome),
    takeLatest(DELETE_SC_SUCCESS, selfCheckDeleted),
    takeLatest(DELETE_LIB_SUCCESS, goHome),
    takeLatest(DO_LOGOUT, logout),
    takeLatest(LOCATION_CHANGE, router),
  ]);
}

export default saga;
