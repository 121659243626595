/* @flow */

import React, { Component } from 'react'
import find from 'lodash/find'

import { Link } from 'react-router-dom'
import { Sidebar, SidebarItem } from '../StyledComponents/Main'
import themeSchema from '../../Data/themeSchema'


export default class ComponentSidebar extends Component<any,any> {

  render() {
    const { lang, id, screen, component } = this.props

    return (
      <Sidebar style={{paddingTop: 81}}>
        { find(themeSchema, x => x.id === screen).sub.map(x =>
          <Link key={x.id} to={'/theme/' + id + '/' + screen + '/' + x.id}>
            <SidebarItem active={component === x.id}>{ lang.theme[x.id] }</SidebarItem>
          </Link>) }
      </Sidebar>
    )
  }
}
