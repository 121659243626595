/* @flow */

import React, { Component } from 'react'
import { SelectContainer, SelectBtn } from '../../StyledComponents/Forms'
import last from 'lodash/last'


export default class SelectInput extends Component<any,any> {

  onChange = (path: Array<string>) => (e: Event) => {
    const { onChange } = this.props
    onChange(path)(e.target.value)
  }

  render() {
    const { type, obj, path, onChange, options, optionCollection, ...rest } = this.props
    const id = last(path)

    return (
      <SelectContainer>
        <select id={id} onChange={this.onChange(path)} value={obj.getIn(path)} {...rest}>
          {
            optionCollection ?
              options.map(x => <option key={x.value} value={x.value}>{x.name}</option>)
            : options.map(x => <option key={x} value={x}>{x}</option>)
          }
        </select>
        <SelectBtn />
      </SelectContainer>
    )
  }
}
